

import React ,{ useState, useEffect, useContext } from 'react';
import { Typography, Grid, Paper, Box, Switch,useMediaQuery, useTheme ,Button, Modal, TextField,Snackbar ,Alert} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import api from '../../api';
import UserContext from '../../UserContext';

import bookingLogo from '../../library/booking.png';
import airbnbLogo from '../../library/airbnb.png';
import expediaLogo from '../../library/expedia.png';
import smartbnbLogo from '../../library/smartbnb_fav.png';
import ReservationModal from './ReservationModal';

const DetailedPropertyComponent = ({ property ,events, reservations}) => {
  const theme = useTheme();
  const { listings, isAdmin, user } = useContext(UserContext);

  console.log(property)
  
  //console.log(property._id)

  // const [events, setEvents] = useState([]);
  // const [reservations, setReservations] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [isOwnersReservationModalOpen, setIsOwnersReservationModalOpen] = useState(false);
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackSeverity, setFeedbackSeverity] = useState('');
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const [metrics, setMetrics] = useState({
    adr: 0,
    avgNights: 0,
    occupancy: 0,
    income: 0,
  });


  useEffect(() => {
    if (selectedMonth !== null && selectedYear !== null) {
      const calculatedMetrics = calculateMetrics(reservations, selectedMonth, selectedYear);
      setMetrics(calculatedMetrics);
    }
  }, [selectedMonth, selectedYear, reservations]);


  const calculateMetrics = (reservations, selectedMonth, selectedYear) => {
    //console.log('Reservations:', reservations); // Add this line to log the reservations data
  
    if (!reservations || reservations.length === 0) {
      console.log('No reservations found'); // Add this line to log if no reservations are found
      return {
        adr: 0,
        avgNights: 0,
        occupancy: 0,
        income: 0,
      };
    }
  
    const filteredReservations = reservations.filter((reservation) => {
      if (!reservation.checkInDateLocalized) {
       // console.log('Reservation missing startDate:', reservation); // Add this line to log reservations missing startDate
        return false;
      }
      //console.log('Reservation startDate:', reservation.checkInDateLocalized); // Add this line to log the startDate of each reservation
      const reservationDate = new Date(reservation.checkInDateLocalized);
      //console.log('Reservation date:', reservationDate); // Add this line to log the parsed reservation date
      return (
        reservationDate.getMonth() === selectedMonth +1 &&
        reservationDate.getFullYear() === selectedYear
      );
    });
  
    //console.log('Filtered Reservations:', filteredReservations); // Add this line to log the filtered reservations
  
    const totalNights = filteredReservations.reduce(
      (sum, reservation) => {

        return sum + (reservation.nightsCount || 0);
      },
      0
    );
    const totalFare = filteredReservations.reduce(
      (sum, reservation) => {

        return sum + (reservation.money?.fareAccommodation || 0);
      },
      0
    );
    const totalIncome = filteredReservations.reduce(
      (sum, reservation) => {

        return sum + (reservation.money?.ownerRevenue || 0);
      },
      0
    );
  
    const adr = totalFare / totalNights;
    const avgNights = totalNights / filteredReservations.length;
    const occupancy =
      (totalNights / (new Date(selectedYear, selectedMonth + 1, 0).getDate())) *
      100;
  
    return {
      adr: isNaN(adr) ? 0 : adr,
      avgNights: isNaN(avgNights) ? 0 : avgNights,
      occupancy: isNaN(occupancy) ? 0 : occupancy,
      income: totalIncome,
    };
  };

  const handleDatesSet = (info) => {
    const { start } = info;
    const month = start.getMonth();
    const year = start.getFullYear();
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReservation(null);
  };

  const handleEventClick = (eventInfo) => {
    if (eventInfo.event.extendedProps.reservationId) {
      const reservation = eventInfo.event.extendedProps.reservation;
      setSelectedReservation(reservation);
      setIsModalOpen(true);
    }
  };

  const handleAddOwnersReservation = () => {
    setIsOwnersReservationModalOpen(true);
  };

  const handleCloseOwnersReservationModal = () => {
    setIsOwnersReservationModalOpen(false);
    setCheckInDate('');
    setCheckOutDate('');
  };

  // const handleSubmitOwnersReservation = () => {
  //   // Logic to submit owner's reservation
  //   console.log('Owner\'s Reservation submitted');
  //   console.log('Check-in Date:', checkInDate);
  //   console.log('Check-out Date:', checkOutDate);
  //   // Perform API call or any other necessary actions
  //   handleCloseOwnersReservationModal();
  // };

  const handleSubmitOwnersReservation = async () => {
    try {
      const reservationData = {
        userId: user.id,
        firstName: 'owners',
       lastName: 'reservation',
       phones: ['12345678'],
       email: 'ownersReservation@owners.com',
        checkInDateLocalized: checkInDate,
        checkOutDateLocalized: checkOutDate,
        listingId: property._id,
        source: 'owners_portal',
        guestsCount: 1,
        // creationInfo: {
        //   owner: {
        //     _id: property.owners[0].id,
        //     fullName: 'Owner Vacation', // Replace with actual data if available

        //   }
        // }

        
      };

      const response = await api.post('/api/ownersReservation', reservationData);

      if (response.status === 200) {
        setFeedbackMessage('Owner\'s Reservation submitted successfully');
        setFeedbackSeverity('success');
        setIsFeedbackOpen(true);
        handleCloseOwnersReservationModal();
      } else {
        setFeedbackMessage('Failed to submit Owner\'s Reservation');
        setFeedbackSeverity('error');
        setIsFeedbackOpen(true);
      }
    } catch (error) {
      console.error('Error submitting Owner\'s Reservation:', error);
      setFeedbackMessage('Error submitting Owner\'s Reservation');
      setFeedbackSeverity('error');
      setIsFeedbackOpen(true);
    }
  };

  const handleCloseFeedback = () => {
    setIsFeedbackOpen(false);
  };


  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Box display="flex" alignItems="center" mb={2}>
        <img src={property.picture?.thumbnail || property.picture?.large} alt={property.name} style={{ width: '120px', height: '120px', marginRight: '20px' }} />
        <Box>
          <Typography variant="h5" gutterBottom>
            {property.title || ''}
          </Typography>

          {isAdmin && (
            <Typography variant="subtitle1" gutterBottom>
              {property.pms.active || 'Guesty'} · {property.nickname || ''}
            </Typography>
          )}
          {/* <Typography variant="subtitle1" gutterBottom>
            {property.pms.active || 'Guesty'} · {property.nickname || ''}
          </Typography> */}
          <Typography variant="subtitle1" gutterBottom>
            {property.address.city} {property.address.country} 
          </Typography>
          <Switch checked={property.active } />
        </Box>
      </Box>
      <Grid container spacing={2}>

      <Grid container spacing={isSmallScreen ? 1 : 2}>

        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f0f4ff' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>ADR</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>€{metrics.adr.toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#e0f7fa' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Avg Nights</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.avgNights.toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f9fbe7' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Occupancy</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metrics.occupancy.toFixed(2)}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#fff3e0' }}>
            <Typography sx={{ fontSize: '14px', color: 'gray' }}>Income</Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>€{metrics.income.toFixed(2)}</Typography>
          </Paper>
        </Grid>
      
     
      

    </Grid>
    <Grid item xs={12}>
    <Button variant="contained" color="primary" onClick={handleAddOwnersReservation}>
      Add Owner's Reservation
    </Button>
  </Grid>

    <Grid item xs={12}>

    <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            eventClick={handleEventClick}
            datesSet={handleDatesSet}

            eventContent={(eventInfo) => {
              const { reservation } = eventInfo.event.extendedProps;
              let logo;

              if (reservation && reservation.source === 'Booking.com') {
                logo = bookingLogo;
              } else if (reservation && reservation.source === 'airbnb') {
                logo = airbnbLogo;
              } else if (reservation && reservation.source === 'airbnb2') {
                logo = airbnbLogo;
              } else if (reservation && reservation.source === 'manual') {
                logo = smartbnbLogo;
              }else {
                // Default logo for other sources
                logo = smartbnbLogo;
              }

              if (reservation) {
                return (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    
                    color: 'white',
                    borderRadius: '5px',
                    padding: '2px 4px',
                    whiteSpace: 'nowrap',
                  }}>
                    {logo && <img src={logo} alt={reservation.source} style={{ width: '20px', height: '20px', marginRight: '5px' }} />}
                    {eventInfo.event.title}
                  </div>
                );
              } else if (eventInfo.event.title.startsWith('€')) {
                return (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    backgroundColor: 'transparent',
                    color: 'black',
                    borderRadius: '5px',
                    padding: '2px 4px',
                  }}>
                    {eventInfo.event.title}
                  </div>
                );
              } else {
                return (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    backgroundColor: 'gray',
                    color: 'white',
                    borderRadius: '5px',
                    padding: '2px 4px',
                  }}>
                    Unavailable
                  </div>
                );
              }
            }}
            eventDidMount={(info) => {
              const { isReservation } = info.event.extendedProps;

              if (isReservation) {
                const eventElement = info.el;
                eventElement.style.backgroundColor = 'rgba(0, 128, 0, 0.7)';
                eventElement.style.borderRadius = '4px';
              }
            }}
          />

</Grid>
       
      </Grid>
      {isModalOpen && selectedReservation && (
        <ReservationModal
          reservation={selectedReservation}
          onClose={handleCloseModal}
        />
      )}

<Modal open={isOwnersReservationModalOpen} onClose={handleCloseOwnersReservationModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Owner's Reservation
          </Typography>
          <TextField
            label="Check-in Date"
            type="date"
            value={checkInDate}
            onChange={(e) => setCheckInDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Check-out Date"
            type="date"
            value={checkOutDate}
            onChange={(e) => setCheckOutDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSubmitOwnersReservation}>
            Submit
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={isFeedbackOpen}
        autoHideDuration={3000}
        onClose={handleCloseFeedback}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseFeedback} severity={feedbackSeverity}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default DetailedPropertyComponent;


